@import "../../lib/functions";
@import "../../lib/mixins";

/**

  ======================
  experts
  ======================

 */
.cont-experts {
  margin-left: 0;
}

.experts {
  box-shadow: 0 4px 18px 0 rgba(196, 196, 196, 0.5);
  display: table;
  font-size: 16px;
  margin-left: 0;
  margin-top: em(33,16);
  width: 100%;

  &:before {
    content: none;
  }
}

.experts__photo {
  background-size: cover;
  background-position: center center;
  display: table-cell;
  height: 255px;
  position: relative;
  vertical-align: middle;
  width: 255px;
  @include clip-path(polygon(0% 0%, 100% 0%, 92% 100%, 0% 100%));

  &:after {
    background-image: url('../../img/triangle-bleu.png');
    background-size: cover;
    content: '';
    height: 549px;
    right: 0;
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    width: 106px;
  }
}

.experts__texte {
  display: table-cell;
  padding: em(16,16) em(30,16);
  vertical-align: middle;
}

.experts__nom {
  color: #008FFF;
  font-size: em(24,16);
  font-weight: 700;
}
