@import "../../lib/functions";
@import "../../lib/mixins";

/**

  ======================
  bttn
  ======================

 */
.cont-bttn {
  font-size: 18px;
  text-align: center;
}

.bttn {
  border-radius: em(50,18);
  box-shadow: 0 8px 10px 0 rgba(0, 143, 255, 0.19);
  color: #FFFFFF;
  display: inline-block;
  font-weight: 300;
  margin: em(20,18) auto;
  padding: em(15,18) em(55,18) em(15,18) em(33,18);
  overflow: hidden;
  position: relative;
  text-decoration: none;
  //@include transition(background-image 0.3s ease, box-shadow 0.3s ease);

  & span {
    position: relative;
    z-index: 1;
  }

  &:before {
    background-color: #008FFF;
    box-shadow: 0 8px 10px 0 rgba(0, 143, 255, 0.19), 0 200px 100px -100px #008FFF inset;
    content: '';
    height: 200px;
    left: -100px;
    position: absolute;
    top: -100px;
    width: 600px;
    //@include transition(background-color 0.3s ease, box-shadow 0.3s ease);
    @include transform(rotate(-20deg));
  }

  &:after {
    content: url('../../img/fleche-droite-blanc.png');
    height: 19px;
    position: absolute;
    right: em(30,18);
    top: em(15,18);
    width: 10px;
    @include transition(transform 0.3s ease);
  }


  // HOVER
  &:hover {
    box-shadow: 0 8px 10px 0 rgba(238, 42, 123, 0.19);
  }

  &:hover:before {
    background-color: #FF0069;
    box-shadow: 0 8px 10px 0 #FF0069, 0 200px 100px -100px #FF0069 inset;
  }

  &:hover:after {
    transform: translateX(3px);
  }
}

// Bouton de partage linkedin (sans doute temporaire)
.bttn--linkedin {
  background-color: #FFFFFF;
  border-radius: 0;
  box-shadow: 0 8px 10px 0 rgba(205, 0, 90, 0.6);
  color: #0077B5;
  display: inline-block;
  font-size: em(16,18);
  font-weight: 400;
  margin: em(20,16) 0 0 0;
  padding: em(12,16) em(23,16) em(12,16) em(55,16);

  &:before {
    content: none;
  }

  &:after {
    content: url('../../img/linkedin.png');
    height: 28px;
    left: em(13,16);
    right: auto;
    top: em(11,16);
    width: 28px;
  }

  &:hover {
    background-color: #FFFFFF;
  }
}




// Bouton rose
.bttn--rose{
  box-shadow: 0 8px 10px 0 rgba(238, 42, 123, 0.19);

  &:before {
    background-color: #FF0069;
    box-shadow: 0 8px 10px 0 #EE2A7B, 0 200px 100px -100px #FF0069 inset;
  }

  &:hover:before {
    background-color: #FF0069;
    box-shadow: 0 8px 10px 0 #dd0065, 0 200px 100px -100px #EE2A7B inset;
  }
}









//.home-services__card .bttn {
//  box-shadow: 0 8px 10px 0 rgba(90, 90, 90, 0.19);
//
//  &:before {
//    background-color: #5A5A5A;
//    box-shadow: 0 8px 10px 0 #5A5A5A, 0 200px 100px -100px #5A5A5A inset;
//  }
//
//  &:hover:before {
//    background-color: #5A5A5A;
//    box-shadow: 0 8px 10px 0 #5A5A5A, 0 200px 100px -100px #5A5A5A inset;
//  }
//}

// Bouton rose pour les card services de la homepage
.home-services__card:hover .bttn {
  box-shadow: 0 8px 10px 0 rgba(238, 42, 123, 0.19);

  &:before {
    background-color: #FF0069;
    box-shadow: 0 8px 10px 0 #EE2A7B, 0 200px 100px -100px #FF0069 inset;
  }

  &:hover:before {
    background-color: #FF0069;
    box-shadow: 0 8px 10px 0 #dd0065, 0 200px 100px -100px #EE2A7B inset;
  }
}
