/**

  ======================
  font-face : Cabin
  ======================

 */

@font-face {
  font-family: 'Cabin';
  src: url('../../fonts/Cabin-Regular.eot');
  src: url('../../fonts/Cabin-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Cabin-Regular.woff2') format('woff2'),
  url('../../fonts/Cabin-Regular.woff') format('woff'),
  url('../../fonts/Cabin-Regular.svg#Cabin-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../../fonts/Cabin-Bold.eot');
  src: url('../../fonts/Cabin-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Cabin-Bold.woff2') format('woff2'),
  url('../../fonts/Cabin-Bold.woff') format('woff'),
  url('../../fonts/Cabin-Bold.svg#Cabin-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
