
$font__cabin: 'Open Sans', sans-serif;
$width-site: 1024px;


/******************************************/
/*///////////////  LAYOUT  ///////////////*/
/******************************************/
/*-------- Layout grid -------*/
$col-margin: 1.6%;
$col-total: 24;



/**********************************************/
/*///////////////  RESPONSIVE  ///////////////*/
/**********************************************/
/*------- Responsive layout grid -------*/
$grid-large: 1024;
$grid-large-px: 1024px;
$grid-medium: 800;
$grid-medium-px: 800px;
$grid-small: 601;
$grid-small-px: 601px;
$grid-xsmall: 480;
$grid-xsmall-px: 480px;


/**************************************/
/*///////////////  UI  ///////////////*/
/**************************************/
$ui-rouge: #F62459 !default;
$ui-rouge--hover: #DC2459 !default;
$ui-rouge--bg: #FFEEF2 !default;
$ui-rouge--light: #FFE7EA !default;
$ui-orange: #FF9E36 !default;
$ui-orange--hover: #E28935 !default;
$ui-orange--bg: #FEF7EC !default;
$ui-vert: #2ECC71 !default;
$ui-vert--hover: #2BA552 !default;
$ui-vert--bg: #E5FFF0 !default;
$ui-bleu: #15B2FF !default;
$ui-bleu--hover: #159EE7 !default;
$ui-bleu--bg: #E5F6FF !default;
