
@import "../../lib/functions";
@import "../../lib/variables";
@import "../../lib/mixins";


/**********************************/
/*//////////// LAYOUT ////////////*/
/**********************************/
/* Styles par défaut pour la grille flexible */
.row,
.col { box-sizing: border-box; }
.row:before,
.row:after { content: " "; display: table; }
.row:after { clear: both; }
.col {
  float: left;
  position: relative;
}

/* Reinit à cause de wording */
ul.row{ margin: 0; }
li.col{
  line-height: normal;
  padding-left: 0;
}
li.col:before{ content: none; }


.js-clearfix{
  clear: both;
}


/*
 * Mise en forme avec table
 * Permet d'avoir des conteneurs plein (col)
 * ATTENTION : ne permet une prise en compte des m... et s...
 */
.row--tab{
  display: table;
  width: col-defaut($col-total);
}
.row--tab > .col{
  display: table-cell;
  float: none;
}

/*
 * Mise en forme avec inline-block (ib)
 * Ne permet pas d'avoir des conteneurs plein (col)
 */
.row--ib{
  display: table;
  font-size: 0;
  width: col-defaut($col-total);
}
.row--ib-center{ text-align: center; }
.row--ib > .col{
  display: inline-block;
  float: none;
}

/* Alignement vertical */
.row--ib > .col--va-top,
.row--tab > .col--va-top{ vertical-align: top; }
.row--ib > .col--va-middle,
.row--tab > .col--va-middle{ vertical-align: middle; }
.row--ib > .col--va-bottom,
.row--tab > .col--va-bottom{ vertical-align: bottom; }


/**
 * Largeurs par défaut des colonnes
 */
@for $i from 1 through $col-total {
  .row .lg-#{$i} { width: col-defaut($i); }
  .row .offset-lg-0 { margin-left: 0; }
  .row .offset-lg-#{$i} { margin-left: col-defaut($i); }
  .row .pull-lg-0 { right: 0; }
  .row .pull-lg-#{$i} { right: col-defaut($i); }
  .row .push-lg-0 { left: 0; }
  .row .push-lg-#{$i} { left: col-defaut($i); }
}

/**
 * Largeurs des colonnes incluant une marge
 */
@for $i from 1 through $col-total {
  .row .col--margin.lg-#{$i} { width: col-margin($i); }
  .row .col--margin.lg-#{$i}--no-margin { clear: both; margin-left: 0; }
}
.col--margin + .col--margin { margin-left: $col-margin; }



@media only screen and (max-width: $grid-large-px) {
  /**
   * Colonnage par défaut
   */
  @for $i from 1 through $col-total {
    .row .md-#{$i} { width: col-defaut($i); }
    .row .offset-md-0 { margin-left: 0; }
    .row .offset-md-#{$i} { margin-left: col-defaut($i); }
    .row .pull-md-0 { right: 0; }
    .row .pull-md-#{$i} { right: col-defaut($i); }
    .row .push-md-0 { left: 0; }
    .row .push-md-#{$i} { left: col-defaut($i); }
  }

  /**
   * Colonnage avec une marge
   */
  @for $i from 1 through $col-total {
    .row .col--margin.md-#{$i} { width: col-margin($i); }
    .row .col--margin.md-#{$i}--no-margin { clear: both; margin-left: 0; }
  }
  .col--margin + .col--margin.md-#{$col-total} { margin-left: 0; }
}



@media only screen and (max-width: $grid-medium-px) {
  /**
   * Colonnage par défaut
   */
  @for $i from 1 through $col-total {
    .row .sm-#{$i} { width: col-defaut($i); }
    .row .offset-sm-0 { margin-left: 0; }
    .row .offset-sm-#{$i} { margin-left: col-defaut($i); }
    .row .pull-sm-0 { right: 0; }
    .row .pull-sm-#{$i} { right: col-defaut($i); }
    .row .push-sm-0 { left: 0; }
    .row .push-sm-#{$i} { left: col-defaut($i); }
  }


  /**
   * Colonnage avec une marge
   */
  @for $i from 1 through $col-total {
    .row .col--margin.sm-#{$i} { width: col-margin($i); }
    .row .col--margin.sm-#{$i}--no-margin { clear: both; margin-left: 0; }
  }
  .col--margin + .col--margin.sm-#{$col-total} { margin-left: 0; }
}




@media only screen and (max-width: $grid-small-px) {
  /**
   * Colonnage par défaut
   */
  @for $i from 1 through $col-total {
    .row .xs-#{$i} { width: col-defaut($i); }
    .row .offset-xs-0 { margin-left: 0; }
    .row .offset-xs-#{$i} { margin-left: col-defaut($i); }
    .row .pull-xs-0 { right: 0; }
    .row .pull-xs-#{$i} { right: col-defaut($i); }
    .row .push-xs-0 { left: 0; }
    .row .push-xs-#{$i} { left: col-defaut($i); }
  }


  /**
   * Colonnage avec une marge
   */
  @for $i from 1 through $col-total {
    .row .col--margin.xs-#{$i} { width: col-margin($i); }
    .row .col--margin.xs-#{$i}--no-margin { clear: both; margin-left: 0; }
  }
  .col--margin + .col--margin.xs-#{$col-total} { margin-left: 0; }
}



@media only screen and (max-width: $grid-xsmall-px) {
  .row .xs-1,
  .row .xs-2,
  .row .xs-3,
  .row .xs-4,
  .row .xs-5,
  .row .xs-6,
  .row .xs-7,
  .row .xs-8,
  .row .xs-9,
  .row .xs-10,
  .row .xs-11,
  .row .xs-12,
  .row .xs-13,
  .row .xs-14,
  .row .xs-15,
  .row .xs-16,
  .row .xs-17,
  .row .xs-18,
  .row .xs-19,
  .row .xs-20,
  .row .xs-21,
  .row .xs-22,
  .row .xs-23,
  .row .xs-24,
  .row .sm-1,
  .row .sm-2,
  .row .sm-3,
  .row .sm-4,
  .row .sm-5,
  .row .sm-6,
  .row .sm-7,
  .row .sm-8,
  .row .sm-9,
  .row .sm-10,
  .row .sm-11,
  .row .sm-12,
  .row .sm-13,
  .row .sm-14,
  .row .sm-15,
  .row .sm-16,
  .row .sm-17,
  .row .sm-18,
  .row .sm-19,
  .row .sm-20,
  .row .sm-21,
  .row .sm-22,
  .row .sm-23,
  .row .sm-24,
  .row .md-1,
  .row .md-2,
  .row .md-3,
  .row .md-4,
  .row .md-5,
  .row .md-6,
  .row .md-7,
  .row .md-8,
  .row .md-9,
  .row .md-10,
  .row .md-11,
  .row .md-12,
  .row .md-13,
  .row .md-14,
  .row .md-15,
  .row .md-16,
  .row .md-17,
  .row .md-18,
  .row .md-19,
  .row .md-20,
  .row .md-21,
  .row .md-22,
  .row .md-23,
  .row .md-24,
  .row .lg-1,
  .row .lg-2,
  .row .lg-3,
  .row .lg-4,
  .row .lg-5,
  .row .lg-6,
  .row .lg-7,
  .row .lg-8,
  .row .lg-9,
  .row .lg-10,
  .row .lg-11,
  .row .lg-12,
  .row .lg-13,
  .row .lg-14,
  .row .lg-15,
  .row .lg-16,
  .row .lg-17,
  .row .lg-18,
  .row .lg-19,
  .row .lg-20,
  .row .lg-21,
  .row .lg-22,
  .row .lg-23,
  .row .lg-24,
  .row .col--margin.xs-1,
  .row .col--margin.xs-2,
  .row .col--margin.xs-3,
  .row .col--margin.xs-4,
  .row .col--margin.xs-5,
  .row .col--margin.xs-6,
  .row .col--margin.xs-7,
  .row .col--margin.xs-8,
  .row .col--margin.xs-9,
  .row .col--margin.xs-10,
  .row .col--margin.xs-11,
  .row .col--margin.xs-12,
  .row .col--margin.xs-13,
  .row .col--margin.xs-14,
  .row .col--margin.xs-15,
  .row .col--margin.xs-16,
  .row .col--margin.xs-17,
  .row .col--margin.xs-18,
  .row .col--margin.xs-19,
  .row .col--margin.xs-20,
  .row .col--margin.xs-21,
  .row .col--margin.xs-22,
  .row .col--margin.xs-23,
  .row .col--margin.xs-24,
  .row .col--margin.sm-1,
  .row .col--margin.sm-2,
  .row .col--margin.sm-3,
  .row .col--margin.sm-4,
  .row .col--margin.sm-5,
  .row .col--margin.sm-6,
  .row .col--margin.sm-7,
  .row .col--margin.sm-8,
  .row .col--margin.sm-9,
  .row .col--margin.sm-10,
  .row .col--margin.sm-11,
  .row .col--margin.sm-12,
  .row .col--margin.sm-13,
  .row .col--margin.sm-14,
  .row .col--margin.sm-15,
  .row .col--margin.sm-16,
  .row .col--margin.sm-17,
  .row .col--margin.sm-18,
  .row .col--margin.sm-19,
  .row .col--margin.sm-20,
  .row .col--margin.sm-21,
  .row .col--margin.sm-22,
  .row .col--margin.sm-23,
  .row .col--margin.sm-24,
  .row .col--margin.md-1,
  .row .col--margin.md-2,
  .row .col--margin.md-3,
  .row .col--margin.md-4,
  .row .col--margin.md-5,
  .row .col--margin.md-6,
  .row .col--margin.md-7,
  .row .col--margin.md-8,
  .row .col--margin.md-9,
  .row .col--margin.md-10,
  .row .col--margin.md-11,
  .row .col--margin.md-12,
  .row .col--margin.md-13,
  .row .col--margin.md-14,
  .row .col--margin.md-15,
  .row .col--margin.md-16,
  .row .col--margin.md-17,
  .row .col--margin.md-18,
  .row .col--margin.md-19,
  .row .col--margin.md-20,
  .row .col--margin.md-21,
  .row .col--margin.md-22,
  .row .col--margin.md-23,
  .row .col--margin.md-24,
  .row .col--margin.lg-1,
  .row .col--margin.lg-2,
  .row .col--margin.lg-3,
  .row .col--margin.lg-4,
  .row .col--margin.lg-5,
  .row .col--margin.lg-6,
  .row .col--margin.lg-7,
  .row .col--margin.lg-8,
  .row .col--margin.lg-9,
  .row .col--margin.lg-10,
  .row .col--margin.lg-11,
  .row .col--margin.lg-12,
  .row .col--margin.lg-13,
  .row .col--margin.lg-14,
  .row .col--margin.lg-15,
  .row .col--margin.lg-16,
  .row .col--margin.lg-17,
  .row .col--margin.lg-18,
  .row .col--margin.lg-19,
  .row .col--margin.lg-20,
  .row .col--margin.lg-21,
  .row .col--margin.lg-22,
  .row .col--margin.lg-23,
  .row .col--margin.lg-24{
    float: none;

    /* Anciennement --> width: auto; */
    width: 100%;
  }

  .row.row--tab .col {
    display: block;
    float: none;
  }

  .col--margin + .col--margin{ margin-left: 0; }
}
/**************************************/
/*//////////// FIN LAYOUT ////////////*/
/**************************************/