@import "../../lib/functions";
@import "../../lib/mixins";

/**

  ======================
  layout pour le header principal
  ======================

 */

.l-header + article{
  margin-top: -140px;
}


// Layout logo + navigation
.l-header__cont-logo-menu {
  background: linear-gradient(to bottom, rgb(0, 0, 0) 20%,rgba(125,185,232,0) 100%);
  position: relative;
  //padding: em(17,16) 0;
  top: em(17,16);
  width: 100%;
  z-index: 99999;
}
.l-header__cont-logo {
  float: left;
  width: pourcentage(211,1024);
}

.l-header__cont-navigation {
  float: right;
}


// Bg du header principal
.l-header__bg {
  background-position: center center;
  background-size: cover;
  min-height: 350px;
  @include clip-path(polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%));

  &:before {
    content: '';
    background-color: #008FFF;
    mix-blend-mode: multiply;
    height: 800px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}



// Navigation
.navigation {

  & ul {
    margin-left: 0;
  }

  & li {
    display: inline-block;
    margin-left: 0;
    vertical-align: middle;

    &:before {
      content: none;
    }
  }

  & li + li {
    margin-left: em(16,16);
    margin-top: 0;
  }

  & a {
    color: #FFFFFF;
    display: block;
    font-size: em(20,16);
    height: 33px;
    overflow: hidden;
    text-decoration: none;
    position: relative;

    &:after {
      background: linear-gradient(to right, rgb(238, 42, 123) 0%, rgb(238, 42, 123) 0%);
      bottom: em(5,16);
      content: '';
      display: block;
      height: 2px;
      left: 100%;
      overflow: hidden;
      position: absolute;
      width: 100%;
      @include transition(left 0.3s ease);
    }

    &:hover:after {
      left: 0;
    }
  }
}