@import "../../lib/functions";
@import "../../lib/mixins";

/**

  ======================
  wording
  ======================

 */
h1 {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 65px;
  line-height: em(90,65);
  margin-bottom: em(100,65);
  text-shadow: 0 0 4px rgba(0, 143, 255, 0.4);
}

h2 {
  color: #008FFF;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: em(30,48);
  margin-top: em(70,48);
}

p {
  color: #5A5A5A;
  font-size: 16px;
  font-weight: 300;
  line-height: em(24,16);
  margin: em(16,16) 0;
}

ul {
  color: #5A5A5A;
  font-size: 16px;
  font-weight: 300;
  line-height: em(24,16);
  margin: em(16,16) 0;

  & li {
    margin-left: em(30,16);
    position: relative;

    &:before {
      content: url('../../img/puce_12x12.png');
      height: 12px;
      left: em(-26,16);
      position: absolute;
      width: 12px;
    }

    & ul {
      margin: em(8,16) 0;

      & li:before {
        content: url('../../img/puce_pleine_grise_8x8.png');
        height: 8px;
        left: em(-20,16);
        position: absolute;
        width: 8px;
      }
    }
  }

  & li + li {
    margin-top: em(6,16);
  }
}

ol {
  color: #5A5A5A;
  counter-reset: nombre;
  font-size: 16px;
  font-weight: 300;
  line-height: em(24,16);
  margin: em(16,16) 0;

  & li {
    counter-increment: nombre;
    line-height: 1.6;
    list-style: none;
    padding-left: em(28,16);
    position: relative;
  }

  & li + li {
    margin-top: em(6,16);
  }

  & li:before {
    content: counter(nombre);
    color: #008FFF;
    font-size: em(24,16);
    font-weight: normal;
    left: 0;
    position: absolute;
    top: -6px;
  }
}

strong {
  color: #008FFF;
  font-weight: 400;
}

a {
  color: #008FFF;
}


// Mise en forme spéciale
.exergue {
  color: #008FFF;
  font-weight: 700;
}



// Conteneur d'image lorsqu'une légende est associée à l'image
figure[id*="attachment"] {
  float: left;
  margin: em(16,16) em(16,16) em(16,16) 0;
  max-width: 100% !important;
  text-align: center;

  & img {
    width: 100%;
  }
}
figure[id*="attachment"].aligncenter {
  float: none;
  margin: auto;
}
figure[id*="attachment"].alignright {
  float: right;
  margin-left: em(16,16);
  margin-right: 0;
}
figure[id*="attachment"] .wp-caption-text {
  background-color: #F2F2F2;
  color: #989898;
  font-size: em(14,16);
  font-weight: 300;
  margin: 0;
  padding: em(10,16);
}


// Image dans le contenu
.l-article img{
  display: block;
  height: auto;
  max-width: 100%;
}
.l-article img.aligncenter{ margin: auto; }
.l-article img.alignright{
  float: right;
  margin: em(14,16) 0 em(14,16) em(20,16);
  position: relative;
}
.l-article img.alignleft{
  float: left;
  margin: em(14,16) em(20,16) em(14,16) 0;
}





/* Blockquote */
blockquote p{
  color: #008FFF;
  font-size: em(26,16);
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 0;
  padding: em(30,26) em(10,26);
  position: relative;
  text-align: left;
}

blockquote strong,
blockquote p strong {
  color: #008FFF;
}

blockquote {
  float: left;
  max-width: 40%;
  position: relative;
}
blockquote:before {
  content: url('../../img/quote.png');
  left: -30px;
  top: 0;
  position: absolute;
}


/* Table */
table{
  box-shadow: 0 4px 18px 0 rgba(196, 196, 196, 0.5);
  font-weight: 300;
  line-height: 1.4;
  margin-top: em(50,16);
  width: 100% !important;
  @include clearfix();
}
thead{
  background-color: #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  font-weight: 700;
}
thead th,
thead tr td { /* Abération du plugin MCE Table Buttons */
  color: #5A5A5A;
  padding: em(20,16) em(16,16);
  text-align: left;
}
tbody th { font-weight: 700; }
tbody tr{ border-bottom: 1px solid #f2f2f2; }
tbody td{
  color: #5A5A5A;
  font-weight: 300;
  padding: em(16,16);
}






/**

  ========================
  modules impactant
  ========================

 */
.home-services.wording {

  & > h2 {
    color: #5A5A5A;
    text-align: center;
  }
  
  & > p {
    text-align: center;
  }
}



.l-article.wording {
  background-color: #FFFFFF;
  margin: auto;
  padding: em(74,16) em(55,16);
  width: pourcentage(860,964);
  @include clip-path(polygon(0% 22px, 100% 0%, 100% 100%, 0% 100%));

  & h1 {
    color: #5A5A5A;
    font-size: 55px;
    margin-bottom: em(40,55);
    text-shadow: none;
    text-align: center;
  }

  h2 {
    color: #5A5A5A;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: em(40,24);
    margin-top: em(40,24);
  }

  h3 {
    color: #5A5A5A;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: em(26,28);
    margin-top: em(60,28);
  }
}





/**

  ========================
  responsive
  ========================

 */
@media only screen and (max-width: $grid-large-px) {

}

@media only screen and (max-width: $grid-medium-px) {
  .l-article.wording {
    width: 100%;
  }
}

@media only screen and (max-width: $grid-small-px) {

}

@media only screen and (max-width: $grid-xsmall-px) {

}
