@import "../../lib/variables";

/**

  ======================
  reset
  ======================

 */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
h1, h2, h3, h4, h5, h6{ font-weight: normal; }
html{
  font-size: 100%;
  height: 100%;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  height: 100%;
  font-family: $font__cabin;
  font-size: 16px;
  line-height: 1;
  margin: auto;
}
body.font-is-loaded {
  font-family: $font__cabin;
}

ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*:after,
*:before,
*::after,
*::before{
  box-sizing: border-box;
}





/**
  Bloc rose pour la partie contact et temoignage
 */
.cont-bloc-rose {
  box-shadow: 0 8px 10px 0 rgba(238, 42, 123, 0.19);
}
.bloc-rose {
  background-image: linear-gradient( -41deg, #FF0069 0%, #EE2A7B 100%);
  @include clip-path(polygon(0% 22px, 100% 0%, 100% 100%, 0% 100%));
  padding: em(70,16) em(25,16) em(70,16) em(25,16);
}
.bloc-contact.bloc-rose {
  padding: em(55,16) em(35,16);
}



.bloc-contact__titre {
  color: #FFFFFF;
  font-size: em(24,16);
  font-weight: 700;
  margin-top: 0;
}
.bloc-contact__infos {
  color: #FFFFFF;
  font-size: em(18,16);
  font-weight: 400;
  margin-bottom: 0;

  & span {
    display: block;
  }
}











/**

  =====================
  classes générique
  =====================

 */
.centrage{
  margin-left: auto;
  margin-right: auto;
  padding: 0 em(30,16);
  position: relative;
  width: $width-site;
}

@media screen and (max-width: $width-site) {
  .centrage {
    width: 100%;
  }
}
