@import "../../lib/functions";
@import "../../lib/mixins";

/**

  ======================
  footer
  ======================

 */
.main-footer {
  border-top: 1px solid #F0F0F0;
  font-size: 16px;
  margin-top: em(80,16);
}

// Logo et copyright
.main-footer__logo {
  margin-bottom: em(30,16);
}
.main-footer__logo svg {
  display: block;
  margin: em(25,16) auto em(14,16) auto;
}

.main-footer__logo p {
  color: #8A8A8A;
  font-size: em(16,16);
  margin: 0;
  text-align: center;
}

.main-footer__menu {
  text-align: center;
  margin: 0;

  & li {
    display: inline-block;
    margin: 0 em(10,16) em(10,16) 0;

    &:before {
      content: none;
    }
  }

  & a {
    color: #8A8A8A;
    font-size: em(18,16);
    @include transition(color 0.3s ease);

    &:hover {
      color: #747474;
    }
  }
}