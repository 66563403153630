@import "../../lib/functions";
@import "../../lib/mixins";

/**

  ======================
  home-services
  ======================

 */
.home-services {
  font-size: 16px;
}

.home-services__cont-cards {
  @include clearfix();
}

.home-services__card {
  float: left;
  margin: em(70,16) pourcentage(8,964) 0 pourcentage(8,964);
  position: relative;
  width: pourcentage(305,964);
  //@include transition(filter 0.3s ease);
}

.home-services__shadow {
  background-color: #FFFFFF;
  padding: em(70,16) em(30,16) em(30,16) em(30,16);
  @include clip-path(polygon(0% 20px, 100% 0%, 100% 100%, 0% 100%));

  & h3 {
    color: #5A5A5A;
    font-size: em(38,16);
    font-weight: 700;
    margin-bottom: em(32,38);
    margin-top: em(40,38);
    text-align: center;
    //@include transition(color 0.3s ease);
  }
}

.home-services__card:hover {
  filter: drop-shadow(0 4px 10px rgba(196, 196, 196, 0.5));

  & h3 {
    color: #FF0069;
  }

  & svg [id*="item-couleur"] {
    fill: #FF0069;
  }
}



svg [id*="item-couleur"]{
  //@include transition(fill 0.3s ease);
}


#svg--coaching,
#svg--conseil,
#svg--formation{
  display: block;
  left: 50%;
  margin: auto;
  max-width: 55%;
  position: absolute;
  top: -34px;
  z-index: 9;
  @include transform(translateX(-50%));
}





/**

  ========================
  responsive
  ========================

 */
@media only screen and (max-width: $grid-large-px) {

}

@media only screen and (max-width: $grid-medium-px) {
  .home-services__card {
    filter: drop-shadow(0 4px 10px rgba(196, 196, 196, 0.5));
    clear: both;
    float: none;
    margin: 0;
    margin-top: em(50,16);
    width: 100%;
  }

  #svg--coaching,
  #svg--conseil,
  #svg--formation {
    max-width: 25%;
  }
}

@media only screen and (max-width: $grid-small-px) {
  #svg--coaching,
  #svg--conseil,
  #svg--formation {
    max-width: 35%;
  }
}

@media only screen and (max-width: $grid-xsmall-px) {
  #svg--coaching,
  #svg--conseil,
  #svg--formation {
    max-width: 45%;
  }
}
