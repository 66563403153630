@charset "UTF-8";

/**
  Afin de gérer efficacement les imbrications de em
  $pixels correspond à la valeur en pixels désirés
  $context est la valeur en pixel du context
 */
$browser-context: 16; // Context par défaut
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

/**
  Calcul une largeur en pourcentage en fonction du context donné
 */
@function pourcentage($pixels, $context) {
  @return ($pixels/$context) * 100%;
}


/***********************************************/
/*///////////////  LAYOUT GRID  ///////////////*/
/***********************************************/
/**
 * Largeur par défaut des colonnes
 * $nb-col : nombre de colonne pour lequel on souhaite calculer sa longueur en %
 * return => largeur en %
 */
@function col-defaut($nb-col){
  @return ( 100% / $col-total ) * $nb-col;
}

/* Détermination de la largeur d'une colonne en fonction de la marge définie */
@function largeur-col( $col-total ){
  @return ( 100 - ( $col-margin * ( $col-total - 1 ))) / $col-total;
}
/**
 * Calcul de la largeur des colonnes avec prise en compte d'une marge
 * Fonction pouvant s'appliquer pour les tableaux
 */
@function col-margin($nb-col){
  @return ( largeur-col( $col-total ) * $nb-col ) + ( $col-margin * ( $nb-col - 1 ));
}