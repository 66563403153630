@import "../../lib/functions";
@import "../../lib/mixins";
@import "../../lib/variables";

/* Réinitialisation à cause de wording */
.form-defaut p{ margin-top: 0; }
.form-defaut ul{ margin: 0; }
.form-defaut ul li{
  line-height: normal;
  padding-left: 0;
}
.form-defaut ul li + li{ margin-top: 0; }
.form-defaut ul li:before{ content: none; }


/*********************************************/
/*/////////////// FORMULAIRE ////////////////*/
/*********************************************/
.form-defaut{ font-size: 16px; }

/* Margin bottom pour chaque conteneur de champs */
.form-defaut .form-defaut__cont-field{
  margin-top: em(40,16);
  position: relative;
}

/******************************************/
/*      ETATS : conteneur des champs      */
/******************************************/
.form-defaut .form-defaut__cont-field.is-disabled{
  opacity: 0.4;
}





/******************************************/
/*///////////////  LABELS  ///////////////*/
/******************************************/
// Ajout indication (*) pour champs obligatoire
.form-defaut .obligatoire .label-main:after,
.form-defaut .obligatoire .label-file:after{
  content: '';
  color: $ui-rouge;
  font-size: em(18,16);
  margin-left: em(5,18);
  position: absolute;
}

.form-defaut .form-defaut__cont-field:not(.obligatoire) .label-main:after {
  content: '(Facultatif)';
  color: #b0b0b0;
  font-size: em(14,16);
  margin-left: em(5,14);
  position: absolute;
}

// Le label des champs de formulaire
.form-defaut .label-main,
.form-defaut .label-sub,
.form-defaut .liste-radio .wpcf7-list-item-label,
.form-defaut .wpcf7-radio .wpcf7-list-item-label,
.form-defaut .liste-checkbox .wpcf7-list-item-label,
.form-defaut .wpcf7-checkbox .wpcf7-list-item-label{
  color: #5A5A5A;
  display: inline-block;
  font-size: em(16,16);
  font-weight: 400;
  padding: em(5,16) 0 em(12,16) 0;
  @include transition(color 0.3s ease);
}

/*------- Ajustements des labels -------*/
// Pour les listes (sub, radio et checkbox)
.form-defaut .label-sub,
.form-defaut .liste-radio .wpcf7-list-item-label,
.form-defaut .wpcf7-radio .wpcf7-list-item-label,
.form-defaut .liste-checkbox .wpcf7-list-item-label,
.form-defaut .wpcf7-checkbox .wpcf7-list-item-label{ padding: em(5,16) 0; }

// Affichage d'un nombre de caractère (via classe JS ComptageCharField)
.form-defaut .nb-char-alone + .label-main{
  padding: em(5,18) em(50,18) em(12,18) 0;
}

/**********************************/
/*      MODIFIEURS : labels       */
/**********************************/
// Label général d'un groupe de champs (comme les radios et checkbox)
.form-defaut .label-main--titre-groupe{ font-size: em(22,16); }

// Label à l'intérieur des champs
.form-defaut .label-main--inside{
  color: #b0b0b0;
  font-size: em(16,16);
  top: em(16,16);
  left: em(16,16);
  line-height: 1.22;
  padding: 0;
  position: absolute;
  @include transition(transform 0.2s ease, top 0.2s ease, left 0.2s ease, font-size 0.2s ease, color 0.2s ease);
  z-index: 5;
}

/****************************/
/*      ETATS : labels      */
/****************************/
/*------- .is-actif-field -------*/
// Labels défaut
.form-defaut .is-actif-field .label-main{
  color: #008fff;
}

// Label inside
.form-defaut .is-actif-field .label-main--inside{
  color: #008fff;
  font-size: em(13,16);
  left: 0;
  top: em(-20,13);
  @include transform(none);
}


/*------- Success +/- .is-actif-field -------*/
.form-defaut .is-ok:not(.form-defaut__cont-field--radio) .label-main,
.form-defaut .is-ok:not(.form-defaut__cont-field--checkbox) .label-file,
.form-defaut .is-actif-field.is-ok:not(.form-defaut__cont-field--radio) .label-main,
.form-defaut .is-actif-field.is-ok:not(.form-defaut__cont-field--checkbox) .label-file{
  color: $ui-vert;
}

/*------- Erreur +/- .is-actif-field -------*/
.form-defaut .is-error .label-main,
.form-defaut .is-error .label-file,
.form-defaut .is-actif-field.is-error .label-main,
.form-defaut .is-actif-field.is-error .label-file{
  color: $ui-rouge;
}


/******************************************************************/
/*///////////////  INPUT TEXT / SELECT / TEXTAREA  ///////////////*/
/******************************************************************/
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="checkbox"],
input[type="radio"],
select,
textarea{ font-size: 18px; }

.form-defaut input[type="text"],
.form-defaut input[type="password"],
.form-defaut input[type="tel"],
.form-defaut input[type="email"],
.form-defaut select,
.form-defaut textarea,
.form-defaut .input-file{
  @include appearance(none);
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 0;
  color: #5A5A5A;
  font-size: em(18,16);
  font-weight: 300;
  margin-bottom: 0;
  margin-left: 0;
  padding: em(13,18);
  position: relative;
  @include transition(background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease);
  width: 100%;
  z-index: 3;
}

.form-defaut input[type="text"][disabled]:hover,
.form-defaut input[type="password"][disabled]:hover,
.form-defaut input[type="tel"][disabled]:hover,
.form-defaut input[type="email"][disabled]:hover,
.form-defaut select[disabled]:hover,
.form-defaut textarea[disabled]:hover{ cursor: default; }

// :focus
.form-defaut input[type="text"]:focus,
.form-defaut input[type="password"]:focus,
.form-defaut input[type="tel"]:focus,
.form-defaut input[type="email"]:focus,
.form-defaut select:focus,
.form-defaut textarea:focus,
.form-defaut .input-file:focus{
  border: 1px solid #008fff;
  outline-offset: 0;
  outline: -webkit-focus-ring-color auto 0 !important;
}


/*****************************************/
/*      MODIFIEURS + ETATS : champs      */
/*****************************************/
/*

  Ajustement des champs si :
    - le champs et actif : .is-actif-field
    - le label est à "l'intérieur" du champs : .label-main--inside

*/
.form-defaut .is-actif-field .label-main--inside + input[type="text"],
.form-defaut .is-actif-field .label-main--inside + input[type="password"],
.form-defaut .is-actif-field .label-main--inside + input[type="tel"],
.form-defaut .is-actif-field .label-main--inside + input[type="email"],
.form-defaut .is-actif-field .label-main--inside + textarea,
.form-defaut .is-actif-field .label-main--inside + .wpcf7-form-control-wrap input[type="text"],
.form-defaut .is-actif-field .label-main--inside + .wpcf7-form-control-wrap input[type="password"],
.form-defaut .is-actif-field .label-main--inside + .wpcf7-form-control-wrap input[type="tel"],
.form-defaut .is-actif-field .label-main--inside + .wpcf7-form-control-wrap input[type="email"],
.form-defaut .is-actif-field .label-main--inside + .wpcf7-form-control-wrap textarea{
  padding: em(13,18) em(13,18) em(13,18) em(13,18);
}


/****************************/
/*      ETATS : champs      */
/****************************/
/*------- Succes -------*/
// .is-ok
.form-defaut .is-ok input[type="text"],
.form-defaut .is-ok input[type="password"],
.form-defaut .is-ok input[type="tel"],
.form-defaut .is-ok input[type="email"],
.form-defaut .is-ok select,
.form-defaut .is-ok textarea,
.form-defaut .is-ok .input-file{
  background-color: #F7FFF7;
  border: 1px solid $ui-vert;
  box-shadow: 0 0 0 transparent;
}
// .is-ok :focus
.form-defaut .is-ok input[type="text"]:focus,
.form-defaut .is-ok input[type="password"]:focus,
.form-defaut .is-ok input[type="tel"]:focus,
.form-defaut .is-ok input[type="email"]:focus,
.form-defaut .is-ok select:focus,
.form-defaut .is-ok textarea:focus,
.form-defaut .is-ok .input-file:focus{
  background-color: #FFFFFF;
  border: 1px solid $ui-vert;
  box-shadow: 0 0 0 transparent;
}

/*------- Erreur -------*/
// .is-error
.form-defaut .is-error input[type="text"],
.form-defaut .is-error input[type="password"],
.form-defaut .is-error input[type="tel"],
.form-defaut .is-error input[type="email"],
.form-defaut .is-error select,
.form-defaut .is-error textarea,
.form-defaut .is-error .input-file{
  background-color: #FEF8F8;
  border: 1px solid $ui-rouge;
  box-shadow: 0 0 0 transparent;
}
// .is-error :focus
.form-defaut .is-error input[type="text"]:focus,
.form-defaut .is-error input[type="password"]:focus,
.form-defaut .is-error input[type="tel"]:focus,
.form-defaut .is-error input[type="email"]:focus,
.form-defaut .is-error select:focus,
.form-defaut .is-error textarea:focus,
.form-defaut .is-error .input-file:focus{
  background-color: #FFFFFF;
  border: 1px solid $ui-rouge;
  box-shadow: 0 0 0 transparent;
}





/******************************************/
/*///////////////  SELECT  ///////////////*/
/******************************************/
.form-defaut select{ max-height: 54px; } /* FIX FF */

// Option par défaut
.form-defaut select option{
  color: #000000;
  padding: em(5,16) 0 0 em(5,16);
}
.form-defaut select option[disabled]{
  color: #CACACA !important;
}

// Groupe d'option
.form-defaut select optgroup[label]{
  color: #000000;
  font-style: normal;
  padding: em(10,20) 0 0 0;
}
.form-defaut select optgroup[label] option{
  color: #000000;
  font-weight: 300;
  padding: em(5,16) 0 0 em(20,16);
}

// Code pour afficher fléche personnalisée pour tous les navigateurs sur les champs select
@supports ((-webkit-appearance: none) or (-moz-appearance: none) or (-ms-appearance: none) or (appearance: none)){
  .form-defaut select{
    @include appearance(none);
    background: #FFFFFF url("../../lib/img/fleche-select-gris.png") no-repeat scroll 94.2% center;
    cursor: pointer;
    padding-right: em(65,16);
  }
}

/*********************************/
/*      MODIFIEURS : select      */
/*********************************/
// Flèche de selection grise claire
@supports ((-webkit-appearance: none) or (-moz-appearance: none) or (-ms-appearance: none) or (appearance: none)){
  .form-defaut .select--gris-clair select{ background: #FFFFFF url("../../lib/img/fleche-select-gris-clair.png") no-repeat scroll 94.2% center; }
}

/****************************/
/*      ETATS : select      */
/****************************/
// is-actif-field + label-main--inside
.form-defaut .is-actif-field .label-main--inside + select,
.form-defaut .is-actif-field .label-main--inside + .wpcf7-form-control-wrap select{
  padding: em(25,16) em(55,16) em(7,16) em(15,16); /* Fix FF sur mac */
}

// Changement couleur des fléches sur erreur sur navigateurs récents
@supports ((-webkit-appearance: none) or (-moz-appearance: none) or (-ms-appearance: none) or (appearance: none)){
  .form-defaut .is-error select{ background: #FEF8F8 url("../../lib/img/fleche-select-rouge.png") no-repeat scroll 94.2% center; }
}
@supports ((-webkit-appearance: none) or (-moz-appearance: none) or (-ms-appearance: none) or (appearance: none)){
  .form-defaut .is-ok select{ background: #f7fff7 url("../../lib/img/fleche-select-vert.png") no-repeat scroll 94.2% center; }
}





/******************************************/
/*///////////////  RADIOS  ///////////////*/
/******************************************/
// Conteneur spécial pour les radio (à cause des messages d'erreurs)
.form-defaut .form-defaut__cont-field--radio{ z-index: 3; }

// Liste de bouton radio
.form-defaut .liste-radio,
.form-defaut .wpcf7-radio{
  display: block;
  position: relative;
  z-index: 3;
}
// liste radio pour WPCF7
.form-defaut .liste-radio .wpcf7-list-item,
.form-defaut .wpcf7-radio .wpcf7-list-item{
  display: block;
  margin-left: 0;
}
.form-defaut .liste-radio .wpcf7-list-item + .wpcf7-list-item,
.form-defaut .wpcf7-radio .wpcf7-list-item + .wpcf7-list-item{
  margin-top: 0;
}


// On cache les éléments par défaut des boutons radios
.form-defaut input[type="radio"]:not(:checked),
.form-defaut input[type="radio"]:checked {
  left: -9999px;
  position: absolute;
}
// Pour Wordpress (WPCF7), on cache aussi les éléments mais c'est un peu plus tricky !!
.form-defaut .wpcf7-list-item{ position: relative; }
.form-defaut .wpcf7-list-item input[type="radio"]:not(:checked),
.form-defaut .wpcf7-list-item input[type="radio"]:checked{
  font-size: em(22,16);
  height: em(25,24);
  left: em(-5,24);
  opacity: 0;
  position: absolute;
  top: 0;
  width: em(23,24);
  z-index: 1;
}


// Préparation du label
.form-defaut input[type="radio"]:not(:checked) + label,
.form-defaut input[type="radio"]:checked + label,
.form-defaut input[type="radio"]:not(:checked) + .wpcf7-list-item-label,
.form-defaut input[type="radio"]:checked + .wpcf7-list-item-label {
  cursor: pointer;
  display: block;
  line-height: 1.2;
  padding-left: em(36,16); /* ATTENTION ==> ctx: label-main, label-sub */
  padding-right: em(8,16); /* ATTENTION ==> ctx: label-main, label-sub */
  position: relative;
}


// Aspect des radios
/*------- Case -------*/
.form-defaut input[type="radio"]:not(:checked) + label:before,
.form-defaut input[type="radio"]:checked + label:before,
.form-defaut input[type="radio"]:not(:checked) + .wpcf7-list-item-label:before,
.form-defaut input[type="radio"]:checked + .wpcf7-list-item-label:before {
  background: #FFFFFF;
  border-radius: 50%;
  border: 1px solid #706F6F;
  content: '';
  height: em(22,16);
  left: 0;
  position: absolute;
  top: em(3,16);
  @include transition(border 0.3s ease);
  width: em(22,16);
}
// Case (cochée)
.form-defaut input[type="radio"]:checked + label:before,
.form-defaut input[type="radio"]:checked + .wpcf7-list-item-label:before{
  border: 1px solid #706F6F;
}


/*------- Coche -------*/
.form-defaut input[type="radio"]:not(:checked) + label:after,
.form-defaut input[type="radio"]:checked + label:after,
.form-defaut input[type="radio"]:not(:checked) + .wpcf7-list-item-label:after,
.form-defaut input[type="radio"]:checked + .wpcf7-list-item-label:after {
  background-color: #008fff;
  border-radius: 50%;
  content: '';
  height: em(12,16);
  left: em(5,16);
  position: absolute;
  top: em(8,16);
  @include transition(all 0.3s);
  width: em(12,16);
}
// Coche (case pas cochée)
.form-defaut input[type="radio"]:not(:checked) + label:after,
.form-defaut input[type="radio"]:not(:checked) + .wpcf7-list-item-label:after{
  opacity: 0;
  @include transform(scale(0));
  transform: scale(0);
}
// Coche (case cochée)
.form-defaut input[type="radio"]:checked + label:after,
.form-defaut input[type="radio"]:checked + .wpcf7-list-item-label:after{
  opacity: 1;
  @include transform(scale(1));
  transform: scale(1);
}

/*************************************/
/*        MODIFIEURS : radios        */
/*************************************/
/*------- .liste-radio--inline -------*/
// Btn radios les uns à la suite des autres
.form-defaut .liste-radio--inline,
.form-defaut .liste-radio--inline .wpcf7-radio{
  display: block;
  overflow: hidden;
}
.form-defaut .liste-radio--inline li,
.form-defaut .liste-radio--inline .wpcf7-list-item{
  float: left;
  width: 33%;
}




/********************************************/
/*///////////////  CHECKBOX  ///////////////*/
/********************************************/
// Liste de bouton checkbox
.form-defaut .liste-checkbox,
.form-defaut .wpcf7-checkbox{
  display: block;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: em(20,16);
  position: relative;
  z-index: 3;
}
// liste checkbox pour WPCF7
.form-defaut .liste-checkbox .wpcf7-list-item,
.form-defaut .wpcf7-checkbox .wpcf7-list-item{
  display: block;
  margin-left: 0;
}
.form-defaut .liste-checkbox .wpcf7-list-item + .wpcf7-list-item,
.form-defaut .wpcf7-checkbox .wpcf7-list-item + .wpcf7-list-item{
  margin-top: 0;
}

// On cache les éléments par défaut des checkbox
.form-defaut input[type="checkbox"]:not(:checked),
.form-defaut input[type="checkbox"]:checked {
  left: -9999px;
  position: absolute;
}
// Pour Wordpress (WPCF7), on cache aussi les éléments mais c'est un peu plus tricky !!
.form-defaut .wpcf7-list-item{ position: relative; }
.form-defaut .wpcf7-list-item input[type="checkbox"]:not(:checked),
.form-defaut .wpcf7-list-item input[type="checkbox"]:checked{
  font-size: em(22,16);
  height: em(25,22);
  left: em(-4,22);
  opacity: 0;
  position: absolute;
  top: 0;
  width: em(25,22);
  z-index: 1;
}


// Préparation du label
.form-defaut input[type="checkbox"]:not(:checked) + label,
.form-defaut input[type="checkbox"]:checked + label,
.form-defaut input[type="checkbox"]:not(:checked) + .wpcf7-list-item-label,
.form-defaut input[type="checkbox"]:checked + .wpcf7-list-item-label {
  cursor: pointer;
  display: block;
  line-height: 1.2;
  padding-left: em(36,16); /* ATTENTION ==> ctx: label-main, label-sub */
  padding-right: em(8,16); /* ATTENTION ==> ctx: label-main, label-sub */
  position: relative;
}

// Aspect des checkbox
/*------- Case -------*/
// non cochée
.form-defaut input[type="checkbox"]:not(:checked) + label:before,
.form-defaut input[type="checkbox"]:not(:checked) + .wpcf7-list-item-label:before{
  background: #FFFFFF;
  border: 1px solid #706F6F;
  border-radius: 2px;
  content: '';
  height: em(20,16);
  left: 0;
  position: absolute;
  top: em(4,16);
  @include transition(background-color 0.3s ease, border 0.3s ease);
  width: em(20,16);
}
// cochée
.form-defaut input[type="checkbox"]:checked + label:before,
.form-defaut input[type="checkbox"]:checked + .wpcf7-list-item-label:before{
  background: #FFFFFF;
  border: 1px solid #5A5A5A;
  border-radius: 2px;
  content: '';
  height: em(20,16);
  left: 0;
  position: absolute;
  top: em(4,16);
  @include transition(background-color 0.3s ease, border 0.3s ease);
  width: em(20,16);
}

/***********************************/
/*        MODIFIEURS : case        */
/***********************************/
// Ajout de la classe is-square sur le conteneur (checkbox carré)
.form-defaut .is-square input[type="checkbox"]:not(:checked) + label:before,
.form-defaut .is-square input[type="checkbox"]:not(:checked) + .wpcf7-list-item-label:before{
  background: transparent;
  border: 1px solid #706F6F;
}
.form-defaut .is-square input[type="checkbox"]:checked + label:before,
.form-defaut .is-square input[type="checkbox"]:checked + .wpcf7-list-item-label:before{
  background: transparent;
  border: 1px solid $ui-bleu;
}


/*------- Coche -------*/
.form-defaut input[type="checkbox"]:not(:checked) + label:after,
.form-defaut input[type="checkbox"]:checked + label:after,
.form-defaut input[type="checkbox"]:not(:checked) + .wpcf7-list-item-label:after,
.form-defaut input[type="checkbox"]:checked + .wpcf7-list-item-label:after {
  background-color: #008fff;
  font-size: em(10,16); /* ctx: label-main, label-sub */
  font-weight: 700;
  left: em(5,10);
  position: absolute;
  top: em(9,10);
  border-radius: 2px;
  height: em(10,10);
  width: em(10,10);
  @include transition(all 0.3s ease);
}

// Utilisation de font icones Fo ou MoBo pour la coche
.fo .form-defaut input[type="checkbox"]:not(:checked) + label:after,
.fo .form-defaut input[type="checkbox"]:checked + label:after,
.fo .form-defaut input[type="checkbox"]:not(:checked) + .wpcf7-list-item-label:after,
.fo .form-defaut input[type="checkbox"]:checked + .wpcf7-list-item-label:after {
  content: '\e90b';
  font-family: 'fo', sans-serif;
}

// Coche (case pas cochée)
.form-defaut input[type="checkbox"]:not(:checked) + label:after,
.form-defaut input[type="checkbox"]:not(:checked) + .wpcf7-list-item-label:after {
  opacity: 0;
  @include transform(scale(0));
}
// Coche (case cochée)
.form-defaut input[type="checkbox"]:checked + label:after,
.form-defaut input[type="checkbox"]:checked + .wpcf7-list-item-label:after {
  opacity: 1;
  @include transform(scale(1.4));
}

/************************************/
/*        MODIFIEURS : coche        */
/************************************/
/* Ajout de la classe is-square sur le conteneur (coche carré) */
.form-defaut .is-square input[type="checkbox"]:not(:checked) + label:after,
.form-defaut .is-square input[type="checkbox"]:checked + label:after,
.form-defaut .is-square input[type="checkbox"]:not(:checked) + .wpcf7-list-item-label:after,
.form-defaut .is-square input[type="checkbox"]:checked + .wpcf7-list-item-label:after {
  background-color: $ui-bleu;
  color: #FFFFFF;
  content: '';
  font-size: em(14,16);
  font-weight: normal;
  height: em(8,14);
  left: em(6,14);
  position: absolute;
  top: em(10,14);
  @include transition(all 0.3s ease);
  width: em(8,14);
}


/***************************************/
/*        MODIFIEURS : checkbox        */
/***************************************/
/*------- .liste-checkbox--inline -------*/
// Btn checkbox les uns à la suite des autres
.form-defaut .liste-checkbox--inline,
.form-defaut .liste-checkbox--inline .wpcf7-checkbox{ display: block; overflow: hidden; }
.form-defaut .liste-checkbox--inline li,
.form-defaut .liste-checkbox--inline .wpcf7-list-item{ float: left; width: 33%; }



/********************************************/
/*///////////////  TEXTAREA  ///////////////*/
/********************************************/
.form-defaut textarea{
  margin-bottom: -3px; // Ajustement message d'erreur en bottom
  min-height: 435px;
  resize: none;
}





/****************************************/
/*///////////////  FILE  ///////////////*/
/****************************************/
.form-defaut .input-file{ padding: 0; }

// Réinitialisation des tyles par défaut pour le bouton input file
.form-defaut .input-file__input{
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
}
.form-defaut .input-file__input:hover{ cursor: pointer; }

// Nom du fichier qui sera uploadé
.form-defaut .input-file__cont-return:not(:empty){ position: relative; }
.form-defaut .input-file__return{
  color: #000000;
  width: 2000px;
}

// Conteneur de l'input de type file
.form-defaut .input-file__cont{
  display: block;
  min-height: em(50,16);
  padding: em(15,16);
  right: em(5,16);
  top: em(5,16);
  width: 100%;
  z-index: 999;
}
.form-defaut .input-file__cont .btn-defaut:hover{ cursor: pointer; }

@media screen and(max-width: 601px){
  .form-defaut .input-file__cont-return:not(:empty) {
    display: block;
    min-height: em(30,16);
    position: relative;
  }
  .form-defaut .input-file__cont .btn-defaut{
    bottom: 0;
    position: relative;
    right: 0;
    text-align: center;
    width: 100%;
  }
}

// Ajustement pour les input de type FILE
.form-defaut .is-actif-field .label-main--inside + .input-file{
  line-height: 1.3;
  padding: em(25,16) em(220,16) em(7,16) em(15,16);
}
.form-defaut .is-actif-field .label-main--inside + .input-file .input-file__cont{
  min-height: 0; padding: 0;
}





/***********************************************/
/*///////////////  EXPLICATION  ///////////////*/
/***********************************************/
.form-defaut .cont-explication{
  overflow: hidden;
}

// Affichage d'une explication après le label
.form-defaut .explication{
  color: #706F6F;
  display: inline-block;
  font-size: em(14,16);
  font-weight: 300;
}

/******************************************/
/*        MODIFIEURS : Explication        */
/******************************************/
/*------- .explication--bottom -------*/
// Affichage d'une explication en bas du champs
.form-defaut .explication--bottom{
  margin: em(5,16) 0;
  width: 100%;
}

/*------- .explication--exergue -------*/
// Mise en avant d'une explication de champs
.form-defaut .explication--exergue{
  color: #008fff;
  font-weight: 400;
}



/************************************************************/
/*///////////////  MESSAGES ERREUR / SUCCES  ///////////////*/
/************************************************************/
// Messages d'erreurs sous les champs
.form-defaut .msg-error,
.form-defaut .wpcf7-not-valid-tip{
  background-color: $ui-rouge;
  color: #FFFFFF;
  font-size: em(14,16);
  font-weight: 300;
  margin-top: em(-36,14);
  opacity: 0;
  padding: em(10,14) em(10,14) em(10,14) em(15,14);
  position: absolute;
  @include transition(margin-top 0.3s ease, opacity 0.3s ease);
  width: 100%;
  z-index: 2;
}

/********************************************/
/*      ETATS : messages erreur/success     */
/********************************************/
/*------- Erreur -------*/
// Apparition du message d'erreur
.form-defaut .is-error .msg-error,
.form-defaut .is-error .wpcf7-not-valid-tip{
  margin-top: 0;
  opacity: 1;
  position: relative;
}




/*********************************************************************/
/*///////////////  MESSAGES ERREUR / SUCCES (Global)  ///////////////*/
/*********************************************************************/
div.wpcf7-mail-sent-ok,
div.wpcf7-validation-errors,
.is-bg-color div.wpcf7-validation-errors{
  border: 0;
  font-weight: 300;
  margin: 20px 0;
  padding: 22px;
  text-align: center;
}

// Ok, le message a bien été envoyé
div.wpcf7-mail-sent-ok{
  background-color: $ui-vert;
  color: #FFFFFF;
}

// Erreur, le message n'a pas été envoyé
div.wpcf7-validation-errors{
  background-color: $ui-rouge;
  color: #FFFFFF;
}




/*************************************************/
/*///////////////  LOADER (ajax)  ///////////////*/
/*************************************************/
div.wpcf7 .ajax-loader{
  background-image: url('../../lib/img/loaders/ring-gris.gif');
  height: 40px;
  position: absolute;
  width: 40px;
}





/*******************************************/
/*///////////////  CAPTCHA  ///////////////*/
/*******************************************/
/* Recaptcha */
.g-recaptcha{ text-align: center; }
.g-recaptcha > div{
  display: inline-block;
  margin: em(20,16) auto;
}
/* Erreur sur le Recaptcha */
.g-recaptcha.is-error > div{
  border: 1px solid red;
  border-radius: 4px;
}

/* Loader lors d'une requete ajax */
.loader{
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  width: 0;
}





/******************************************/
/*///////////////  SUBMIT  ///////////////*/
/******************************************/
.form-defaut .cont-submit{
  font-weight: 300;
  margin-bottom: em(20,16);
  margin-top: em(20,16);
  text-align: center;
}
.form-defaut input[type="submit"]{
  font-family: 'roboto', sans-serif;
}

.form-defaut .bttn{
  background-color: #008FFF;
  border: 1px solid #008FFF;
  box-shadow: 0 8px 10px 0 rgba(0, 143, 255, 0.19);
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  padding: em(15,18) em(33,18);
}
.form-defaut .bttn:hover{
  background-color: #FF0069;
  border: 1px solid #FF0069;
  box-shadow: 0 8px 10px 0 rgba(255, 0, 105, 0.26);
  color: #FFFFFF;
  cursor: pointer;
}





/**

  ========================
  modules impactant
  ========================

 */
.livre-blanc .form-defaut .form-defaut__cont-field{
  display: inline-block;
  margin-top: 0;
  max-width: 400px;
  vertical-align: top;
}

.livre-blanc .form-defaut .explication {
  color: #FFFFFF;
  font-size: 13px;
}

.livre-blanc .form-defaut .cont-submit {
  display: inline-block;
  margin: 0;
  vertical-align: top;
}

.livre-blanc .form-defaut button[type="submit"].bttn {
  background-color: #FFFFFF;
  border-radius: 0;
  box-shadow: none;
  color: #FF0069;
  font-family: $font__cabin;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding: em(12,20) em(20,20);
  text-transform: uppercase;
  @include transition(box-shadow 0.3s ease);

  &:hover {
    box-shadow: 0 8px 10px 0 rgba(205, 0, 90, 0.6);
    cursor: pointer;
  }

  &:before,
  &:after {
    content: none;
  }
}





/**

  Layout pour Contact form

 */
.width-50 {
  float: left;
  width: pourcentage(462,964);

  + .width-50 {
    margin-left: pourcentage(40,964);
  }
}
.width-100 {
  clear: both;
}




/**

  ========================
  responsive
  ========================

 */
@media only screen and (max-width: $grid-large-px) {

}


@media only screen and (max-width: $grid-medium-px) {
  .width-50 {
    clear: none;
    float: none;
    width: 100%;

    + .width-50 {
      margin-left: 0;
    }
  }
  .width-100 {
    clear: both;
  }
}

@media only screen and (max-width: $grid-small-px) {

}

@media only screen and (max-width: $grid-xsmall-px) {

}