/**

  ======================
  font-face : Open Sans
  ======================

 */

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Regular.eot');
  src: url('../../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-Regular.woff2') format('woff2'),
  url('../../fonts/OpenSans-Regular.woff') format('woff'),
  url('../../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Bold.eot');
  src: url('../../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-Bold.woff2') format('woff2'),
  url('../../fonts/OpenSans-Bold.woff') format('woff'),
  url('../../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Light.eot');
  src: url('../../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-Light.woff2') format('woff2'),
  url('../../fonts/OpenSans-Light.woff') format('woff'),
  url('../../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

