@import "../../lib/functions";
@import "../../lib/mixins";

/**

  ======================
  cards
  ======================

 */
.card-blog {
  background-color: #FFFFFF;
  box-shadow: 0 4px 18px 0 rgba(196, 196, 196, 0.5);
  font-size: 16px;
  margin-bottom: em(20,16);
}

.card-blog__img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 190px;
}

.card-blog__content {
  padding: em(30,16) em(35,16);

  h3 {
    color: #5A5A5A;
    font-size: em(24,16);
    font-weight: 700;
    line-height: em(28,24);
    margin-bottom: em(40,24);
    margin-top: 0;
  }

  a {
    color: #5A5A5A;

    &:hover {
      color: #008FFF;
    }
  }
}