@import "../../lib/functions";
@import "../../lib/mixins";
@import "../../lib/variables";

/**

  ======================
  content pour les pages type
  ======================

 */

// Entete des pages type
.l-content1 {
  @include clearfix();
}

.l-content1__left {
  float: left;
  padding-right: em(50,16); // 105
  text-align: justify;
  width: pourcentage(580,964);
}

.l-content1__right {
  float: left;
  position: relative;
  width: pourcentage(384,964);
}


// Contenu card (photo et texte)
.l-content2__cont-card {
  @include clearfix();
}

.l-content2__photo {
  float: left;
  min-height: 670px;
  position: relative;
  width: pourcentage(409,964);
}

.l-content2__photo img {
  position: absolute;
}

.l-content2__texte {
  background-color: #FFFFFF;
  box-shadow: 0 4px 18px 0 rgba(196, 196, 196, 0.5);
  float: left;
  margin: em(45,16) 0;
  padding: em(45,16);
  position: relative;
  text-align: justify;
  width: pourcentage(555,964);
}




.l-content3__left {
  float: left;
  padding-right: em(60,16); // 105
  text-align: justify;
  width: pourcentage(631,964);
}

.l-content3__right {
  float: left;
  position: relative;
  width: pourcentage(333,964);
}








/**
  Form
 */
.l-content-form {
  margin-top: em(30,16);

  & img {
    height: auto;
    width: 100%;
  }
}





/**

  ========================
  modifieur
  ========================

 */
.l-content2__cont-card--inverse .l-content2__photo {
  float: right;

  & img {
    right: 0;
  }
}

.l-content2__cont-card--inverse .l-content2__texte {
  float: left;
  right: auto;
}





/**

  ========================
  responsive
  ========================

 */
@media only screen and (max-width: $grid-large-px) {

}


@media only screen and (max-width: $grid-medium-px) {

  .l-content1__left,
  .l-content1__right {
    clear: both;
    float: none;
    padding-right: 0;
    width: 100%;
  }

  .l-content1__right {
    max-width: 70%;
    margin: auto;
    text-align: center;
  }


  .l-content2__photo {
    float: left;
    width: pourcentage(200,964);
  }
  .l-content2__texte {
    background-color: rgba(255,255,255,0.95);
    float: left;
    width: pourcentage(764,964);
  }


  .l-content3__left,
  .l-content3__right {
    clear: both;
    float: none;
    padding-right: 0;
    width: 100%;
  }

  .l-content3__right {
    margin-top: em(20,16);
  }
}

@media only screen and (max-width: $grid-small-px) {

}

@media only screen and (max-width: $grid-xsmall-px) {

}
