@import "../../lib/functions";
@import "../../lib/mixins";

/**

  ======================
  livre blanc
  ======================

 */
.livre-blanc {
  filter: drop-shadow(0 8px 10px rgba(238, 42, 123, 0.19));
  font-size: 16px;
  margin: em(90,16) auto;
  width: pourcentage(740,964);
}

.livre-blanc__cont {
  padding: em(52,16) em(40,16);
  @include linear-gradient( -41deg, #EE2A7B 0%, #FF0069 100%);
  @include clip-path(polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%));

  & h2 {
    color: #FFFFFF;
    font-size: em(56,16);
    margin-bottom: em(30,56);
    margin-top: 0;
  }

  & p {
    color: #FFFFFF;
  }
}
