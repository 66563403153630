@import "../../lib/functions";
@import "../../lib/mixins";

/**

  ======================
  sliders
  ======================

 */
.slider {
  font-size: 16px;
  position: relative;
}

.slider__slide {
  padding: 0 em(10,16);
}

.slider__citation {
  color: #FFFFFF;
  font-size: em(18,16);
  font-weight: 300;
  line-height: em(26,18);
  margin: 0;
}
.slider__auteur {
  color: #FFFFFF;
  font-size: em(16,16);
  font-weight: 300;
  line-height: em(18,16);
  margin-bottom: 0;
  margin-top: em(26,16);
  text-align: right;
}

// Pagination custom
.slider__custom-paging {
  background-color: #FFFFFF;
  bottom: 0;
  color: #808080;
  font-size: em(16,16);
  font-weight: 300;
  height: 34px;
  left: 0;
  position: absolute;
  width: 68px;
}

.slider__custom-paging .slick-prev,
.slider__custom-paging .slick-next {
  height: 34px;
  width: 34px;
}

.slider__custom-paging .slick-prev {
  left: 10px;

  &:before {
    content: url('../../img/fleche-droite-gris.png');
    height: 19px;
    left: 0;
    position: absolute;
    top: 8px;
    @include transform(rotate(-180deg));
    width: 10px;
  }
}

.slider__custom-paging .slick-next {
  right: 10px;

  &:before{
    content: url('../../img/fleche-droite-gris.png');
    height: 19px;
    position: absolute;
    right: 0;
    top: 8px;
    width: 10px;
  }
}

