@charset "UTF-8";

/**

  ========================
  linear-gradient
  ========================

 */
@mixin linear-gradient($linear...) {
  background-image: -moz-linear-gradient($linear);
  background-image: -webkit-linear-gradient($linear);
  background-image: -ms-linear-gradient($linear);
  background-image: linear-gradient($linear);
}


/**

  ========================
  transition
  ========================

 */
@mixin transition($transition...) {
  -webkit-transition: $transition;
          transition: $transition;
}
@mixin transition-property($property...) {
  -webkit-transition-property: $property;
          transition-property: $property;
}
@mixin transition-duration($duration...) {
  -webkit-transition-property: $duration;
          transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  -webkit-transition-timing-function: $timing;
          transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
  -webkit-transition-delay: $delay;
          transition-delay: $delay;
}


/**

  ========================
  clip-path
  ========================

 */
@mixin clip-path($clip-path...) {
  -webkit-clip-path: $clip-path;
  -moz-clip-path: $clip-path;
  clip-path: $clip-path;
}


/**

  ========================
  transform
  ========================

 */
@mixin transform($transform...) {
  -webkit-transform: $transform;
      -ms-transform: $transform;
          transform: $transform;
}


/**

  ========================
  appearance
  ========================

 */
@mixin appearance($appearance...) {
  -webkit-appearance: $appearance;
     -moz-appearance: $appearance;
          appearance: $appearance;
}


/**

  ========================
  color-adjust
  ========================

 */
@mixin color-adjust($adjust){
  -webkit-print-color-adjust: $adjust;
  color-adjust: $adjust;
}


/**

  ========================
  clearfix
  ========================

 */
@mixin clearfix(){
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}


/**

  ========================
  flexbox
  ========================

 */
// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}
